<template>
  <div>
    <van-cell-group>
      <van-cell center style="margin-top: 5px">
        <template slot="title">
          <span style="font-size: 20px; color: #57c4b7">{{ list.title }}</span>
        </template>
      </van-cell>
      <van-cell>
        <template slot="title" style="font-size: 16px">
          <span style="color: #57c4b7">发布时间：</span>
          {{ list.pushTime }}
        </template>
      </van-cell>
      <!-- <van-image width="100%" height="6em" :src="list.logo" alt="宣传" /> -->
      <van-cell>
        <template slot="title">
          <div id="content" style="font-size: 14px"></div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import ajax from "../../lib/ajax";
import store from "../../store";
import { Toast } from "vant";
// import AppConsts from "../../lib/appconst";
export default {
  data() {
    return {
      list: [],
      logos: require('@/assets/FGlogo.png'),
      // logos: require('@/assets/JClogo.png'),
      // logos: require('@/assets/Doc.png'),
      // logos: require("@/assets/LClogo.jpg"),
      // imgsrc: require("@/assets/LCInfo.jpeg"),
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
      let id = store.getters["store/dorname"];
      await ajax
        .get("/Api/WechatNews/GetNewsContent?input=" + id)
        .then((res) => {
          this.list = res.data.result;
          let http = this.list.imageKey;
          this.logos =
            http === null || http === ""
              ? this.logos
              : "http://218.63.113.166:4433/api/Attachment/" + http;
          this.list.logo = this.logos;
          console.log(this.list);
          var content = document.getElementById("content");
          console.log(content);
          content.innerHTML = this.list.content;
          // content.parentNode.appendChild(this.list.content);
        })
        .catch((err) => {
          Toast("错误：" + err.message);
          console.log(err.message);
        });
    },
  },
};
</script>

<style>
</style>
